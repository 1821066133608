<script lang="ts">
    import PasswordLogin from "./PasswordLogin.svelte";
    import WebAuthn from "./WebAuthn.svelte";
    import {get} from "@github/webauthn-json";

    type LoginPhase = 'login' | 'webauthn' | 'ok' | 'unauthorized';
    const URL = "/";

    interface LoginApiResponse {
        status: LoginPhase;
        challenge?: any;
        redirect?: string;
    }

    export let phase: LoginPhase = 'login';
    //export let close: () => void;
    let error = "";
    let challenge: any;

    const passwordLogin = async (username: string, password: string, rememberMe: boolean): Promise<LoginApiResponse> => {
        console.log('passwordLogin', username);
        try {
            let result = await fetch(URL + 'api/login3', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: 'include',
                body: JSON.stringify({
                    email: username,
                    pass: password,
                    phase: 1
                })
            });
            let json = await result.json() as LoginApiResponse;
            return json;
        } catch (e) {
            console.log('error', e);
            return {status: 'unauthorized'};
        }
    }

    const webauthnLogin = async (data: any): Promise<any> => {
        try {
            let response = await get({"publicKey": challenge});
            console.log('response', response);
            let result = await fetch(URL + "api/login3", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        pass: response,
                        phase: 2
                    })
                }
            );
            let json = await result.json();
            return json;
        } catch (e) {
            console.log('error', e);
            return {phase: 'unauthorized'};
        }
    }


    const processAction = (a: LoginApiResponse) => {
        console.log('processAction', a);
        phase = a.status;
        if (phase == 'ok') {
            window.location.href = a.redirect!;
        }
        if (phase === 'webauthn') {
            challenge = a.challenge;
        }
        if (phase === 'unauthorized') {
            error = "Tunnistautuminen epäonnistui";
            phase = 'login';
        }
    }

    const handleLogin = async (email, password, remember) => {
        console.log('submit', email);
        let action = await passwordLogin(email, password, remember);
        processAction(action);
    }

    const handleWebauthn = async () => {
        let action = await webauthnLogin({});
        processAction(action);
    }

    $: {
    }
</script>
<span>Kirjaudu Mandoon</span>
<div class="modal-frame">
    <div id="loginerror">{error}</div>
    {#if phase === "login"}
        <PasswordLogin login={handleLogin}></PasswordLogin>
    {/if}
    {#if phase === "webauthn"}
        <WebAuthn login={handleWebauthn}></WebAuthn>
    {/if}
</div>
<style>
    span {
        display: block;
        text-align: center;
        width: 100%;
        padding: 20px 10px 10px;
        color: #000;
        font-size: 18px;
    }

    #loginerror {
        color: #f00;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
</style>

